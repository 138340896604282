import * as React from "react"
import { Link } from "gatsby"

import Fade from "react-reveal/Fade"

import arrow from "../images/arrow.svg"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Details from "../components/details"
import Contact from "../components/contact"
import SectionNoBG from "../components/section_no_bg"

import introTexts from "../../content/about/intro.json"
import contactTexts from "../../content/about/contact.json"
import section2Texts from "../../content/about/section2.json"
import section3Texts from "../../content/about/section3.json"
import detailsTexts from "../../content/about/expect.json"
import voicesTexts from "../../content/about/voices.json"
import quote from "../../content/about/quote.json"


const About = () => {
  function convertToSlug(Text) {
    return Text.toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "") // remove non-word [a-z0-9_], non-whitespace, non-hyphen characters
      .replace(/[\s_-]+/g, "-") // swap any length of whitespace, underscore, hyphen characters with a single -
      .replace(/^-+|-+$/g, "") // remove leading, trailing -
  }
  return (
  <Layout pageTitle="About">
    <Seo title="About"  description="Do you run a software startup at an early stage and need support? Learn more about what Djäkne Startup Studio can do for you." />
    <SectionNoBG
        classes="first-section"
        headline={introTexts.headline}
        text={introTexts.text}
        narrow = {true}
      >
      </SectionNoBG>
      <Fade bottom duration={1500} fraction={0.1} distance={"200px"}>
      <SectionNoBG
        autoHeight="true"
        headline={section2Texts.headline}
        text={section2Texts.text}
        withImage="right"
        image={section2Texts.image}
      >
      </SectionNoBG>
      <Details texts={detailsTexts} />
      <SectionNoBG
        autoHeight="true"
        headline={section3Texts.headline}
        text={section3Texts.text}
        withImage="left"
        image={section3Texts.image}
      ></SectionNoBG>
       <SectionNoBG  narrow = {true}  autoHeight="true">
            <p className="quote mt-5">{quote.quote}</p>
            <p className="mt-4">{quote.name_role}</p>
        </SectionNoBG>
        <SectionNoBG headline={voicesTexts.headline}  autoHeight="true">
          <div className="container-xxl p-0 pt-3 px-xxl-1">
            <div className="row justify-content-start gx-sm-4">
              {voicesTexts.voices.map((voice, index) => (
                <Link
                  key={index}
                  className="div-link col-sm-4 pt-3 d-flex flex-column text-item"
                  to={`/feed/${convertToSlug(voice.link_to_post)}`}
                >
                  <img
                    loading="lazy"
                    className="mb-4 company-image global-image-style"
                    src={voice.image}
                    alt={voice.name}
                  />
                  <h4 className="mb-1">{voice.name}</h4>
                  <h6 className="mb-4">{voice.title}</h6>
                  <p className=" flex-fill">{voice.quote}</p>
                  <div className="arrow-button toggleColor d-flex flex-row justify-content-start align-items-center mb-5">
                    <p className="mb-0">Read more</p>
                    <img alt="arrow" className="mx-3" src={arrow} width="36" />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </SectionNoBG>
        <Contact texts={contactTexts} />
      </Fade>
  </Layout>
)
}
export default About
