import * as React from "react"
import "./details.module.scss"

import arrow from "../images/arrow.svg"

function Details(props) {
  return (
    <section className="contact">
      <div className="container-fluid">
        <div className="container-xxl">
          <div className="row align-items-center ">
            <div className="col-12 d-flex flex-row ">
              <h1 className="mb-3 mb-md-0">{props.texts.section_headline}</h1>
            </div>
            <div className="d-flex flex-md-row flex-column align-items-md-center">
              <div className="d-flex flex-grow-1 flex-column text-start justify-content-start mb-4 my-md-5">
                {props.texts.areas.map((group, index) => (
                  <button
                    key={`details-flush-collapse${index}`}
                    href=""
                    className={
                      index === 0
                        ? `btn btn-link text-left arrow-button large toggleColor px-0 ps-md-3 py-2 py-md-3 me-md-3 `
                        : `btn btn-link text-left arrow-button large toggleColor px-0 ps-md-3 py-2 py-md-3 me-md-3 collapsed`
                    }
                    aria-expanded={index === 0 ? `true ` : `false`}
                    data-bs-toggle="collapse"
                    data-bs-target={`#details-flush-collapse${index}`}
                  >
                    <p className="mb-0 hover-show">{group.area_headline}</p>
                    <img alt="arrow" className="mx-3" src={arrow} width="36" />
                  </button>
                ))}
              </div>
              <div
                className=" flex-grow-1 accordion accordion-flush"
                id="accordionFlushExample"
              >
                {props.texts.areas.map((group, i) => (
                  <div className="accordion-item" key={`accordion-item${i}`}>
                    <div
                      id={`details-flush-collapse${i}`}
                      className={
                        i === 0
                          ? `accordion-collapse show`
                          : `accordion-collapse collapse`
                      }
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body p-3 p-md-4 p-lg-5 ">
                        <h3 className="my-1 mb-3">{group.area_headline}</h3>
                        <p className="mb-3">{group.area_text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Details
